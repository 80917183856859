import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles(theme =>
  createStyles({
    tag: {
      ...theme.typography.subtitle2,
      fontWeight: 700,
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      paddingLeft: 14,
      paddingRight: 14,
      borderRadius: theme.spacing(2),
      backgroundColor: "#e0e0e0",
      color: theme.palette.common.grey[700],
    },
    hover: {
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
    },
  }),
);

const VideoTagButton = props => {
  const classes = useStyles();
  const { className } = props;

  const { name, hoverEffect } = props;
  return (
    <Button
      className={classNames(
        classes.tag,
        { [classes.hover]: hoverEffect },
        className,
      )}
    >
      {name}
    </Button>
  );
};

VideoTagButton.defaultProps = {
  hoverEffect: true,
};

VideoTagButton.propsTypes = {
  name: PropTypes.string.isRequired,
  hoverEffect: PropTypes.bool,
};

export default VideoTagButton;
