export default {
  data: [
    {
      name: "歐美片",
      src: require("../../images/others/recommend1.png"),
      link: "",
    },
    {
      name: "紀錄片",
      src: require("../../images/others/recommend2.png"),
      link: "",
    },
    {
      name: "亞洲片",
      src: require("../../images/others/recommend3.png"),
      link: "",
    },
    {
      name: "國片",
      src: require("../../images/others/recommend4.png"),
      link: "",
    },
  ],
};
