import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
// styles
import useStyles from "./styles";

// context
import { useLayoutState } from "../../context/LayoutContext";

// components
import Header from "../MovieHeader";
import Footer from "../Footer";

// pages
import MovieHome from "../../pages/MovieHome";
import MovieDetail from "../../pages/movieDetail";
import VideoList from "../../pages/videoList";
import SearchNotFound from "../../pages/searchNotFound";

function MovieLayout (props) {
  const classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <Header
        color='transparent'
        history={props.history}
        changeStyleOnScroll={{
          height: 0,
        }}
      />
      <div className={classes.content}>
        <Switch>
          <Route exact path='/movie' component={MovieHome} />
          <Route path='/movie/detail' component={MovieDetail} />
          <Route path='/movie/detail2' component={MovieDetail} />
          <Route path='/movie/list' component={VideoList} />
          <Route path='/movie/search_not_found' component={SearchNotFound} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default withRouter(MovieLayout);
