import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Box, Grid, Typography } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { concat, flatten } from "lodash";

import VideoTagButton from "../../components/VideoTagButton";
import VideoListSortingButton from "../../components/VideoListSortingButton";
import MovieAlbum from "../../components/MovieAlbum";
import MovieMenu from "../../components/MovieMenu";

import mock from "../../components/MovieContainer/mock";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      margin: "6.5%",
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
      overflowAnchor: "none",
    },
    title: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
    },
    tagList: {
      marginBottom: theme.spacing(1),
    },
    loadMoreContent: {
      marginTop: theme.spacing(5),
    },
    loadMoreButton: {
      width: 160,
      height: 40,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      border: "solid 1px #757575!important",
      borderRadius: "0!important",
      color: theme.palette.common.grey[800],
      borderColor: theme.palette.common.grey[800],
      ...theme.typography.subtitle1,
      "&:hover": {
        backgroundColor: "#a4a4a4",
      },
    },
  }),
);

const VideoList = props => {
  const classes = useStyles();

  const tags = [
    "中文",
    "台語",
    "健體領域",
    "家庭關係",
    "社會領域",
    "藝術領域",
    "性平教育",
    "情感教育",
    "文化歷史",
    "職人",
    "本土文化",
    "環境教育",
    "生命教育",
    "兒童心理",
    "勞工議題",
    "勵志成長",
    "名人傳記",
    "新住民",
    "真人真事",
    "移民",
  ];
  const recommends = mock.groups.filter(e => e.title === "推薦影片")[0];
  const sampleList = {
    ...recommends,
    title: "",
    movies: flatten(concat(recommends.movies, recommends.movies)).map(
      (e, idx) => ({ ...e, id: idx }),
    ),
  };
  const [listState, setListState] = useState(sampleList);

  const handleClickMore = () => {
    let newState = {
      ...listState,
      movies: flatten(
        concat(listState.movies, recommends.movies, recommends.movies),
      ).map((e, idx) => ({
        ...e,
        id: idx,
      })),
    };
    setListState(newState);
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <MovieMenu />
          </Grid>
          <Grid item xs={12} className={classes.title}>
            <Typography variant='h3'>
              <Box fontWeight='fontWeightBold'>熱門影片</Box>
            </Typography>
          </Grid>
          <Grid container item xs={12} className={classes.tagList} spacing={0}>
            {tags.map(tag => (
              <VideoTagButton key={tag} name={tag} />
            ))}
          </Grid>
          <Grid container item xs={12} justify='flex-end' alignItems='center'>
            <Grid item>
              <VideoListSortingButton />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.recommend}>
                <MovieAlbum
                  item={listState}
                  hideMoreLink={true}
                  hoverMaskMode={"simple"}
                  extraMarginBottom={true}
                />
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justify='center'
          className={classes.loadMoreContent}
        >
          <Grid item>
            <Button
              variant='outlined'
              className={classes.loadMoreButton}
              onClick={handleClickMore}
            >
              看更多影片
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

VideoList.defaultProps = {};

VideoList.propsTypes = {
  topicName: PropTypes.string.isRequired,
};

export default VideoList;
