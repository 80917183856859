import React from "react";

// styles
import useStyles from "./styles";

import mock from "./mock";

// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Scrollbar, Mousewheel, Autoplay, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, Mousewheel, Autoplay, A11y]);

export default function PagSwiper(props) {
  const classes = useStyles()
  return (
    <div className={classes.themeColor}>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation
        mousewheel
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
        autoplay
        loop={true}
      >
        {mock.banners.map(banner => (
          <SwiperSlide key={`banner-${banner.id}`}>
            <img
              src={banner.src}
              alt={`banner-${banner.id}`}
              className={classes.banner}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
