import React, { useEffect, useRef } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  VimePlayer,
  VimeHls,
  VimeDefaultUi,
  VimeSpinner,
  VimeControls,
  VimeControlGroup,
  VimeScrubberControl,
  VimePlaybackControl,
  VimeVolumeControl,
  VimeDefaultSettings,
  VimeSettingsControl,
  VimeCaptionControl,
  VimeFullscreenControl,
  VimeControlSpacer,
  VimeTimeProgress,
} from "@vime/react";
import { isArrayLike } from "lodash";

import VimeSeekButtonControl from "../../components/VimeSeekButtonControl";
import VideoMask from "../../components/VideoMask";
import { en, zh } from "./playerLang";

// Default theme.
import "@vime/core/themes/default.css";

// Optional light theme (extends default).
// import '@vime/core/themes/light.css';

const hlsConfig = {
  manifestLoadingMaxRetry: 10,
  manifestLoadingRetryDelay: 200,
  maxBufferLength: 60,
  // maxMaxBufferLength: 10,
  debug: true,
};

const defaultLang = "zh-Hant";

export default function VimeJsPlayer (props) {
  const { url } = props;

  // https://dev.to/savagepixie/how-to-mimic-componentdidupdate-with-react-hooks-3j8c

  const theme = useTheme();
  const player = useRef(null);

  const canSetPlaybackQuality = async event => {
    // console.log('canSetPlaybackQuality:', event, player.playbackQuality, player.playbackQualities)
    // console.log(player.getAdapter)
    const adpater = await player.current.getAdapter();
    const hls = await adpater.getInternalPlayer();
    console.log(hls.levels);
    // console.log(adpater)
    // .then(rs => console.log('internal:', rs.getInternalPlayer().then(e => console.log('e:', e.levels))))
  };

  const setDefaultTextTrack = e => {
    if (isArrayLike(e.detail) && isArrayLike(player.current.textTracks)) {
      e.detail.forEach((track, idx) => {
        if (track.language === defaultLang) {
          player.current.textTracks[idx].mode = "showing";
          return;
        }
      });
    }
  };

  const handlePlaybackQualityChange = async e => {
    if (e.detail === "自動") {
      const adpater = await player.current.getAdapter();
      const hls = await adpater.getInternalPlayer();
      hls.currentLevel = -1;
    }
  };

  const onReady = () => {
    // using the `extendLanguage` method.
    player.current.extendLanguage(defaultLang, zh);
    // TODO: bug
    player.current.extendLanguage("en", zh);
  };

  const Hls = ({ url }) => {
    return (
      <VimePlayer
        style={{
          "--vm-player-theme": theme.palette.primary.main,
          "--vm-icon-color": "white",
          "--vm-control-bg": "rgba(255,255,255,0)",
          "--vm-controls-bg": "rgba(255,255,255,0)",
          "--vm-icon-width": "18px",
          "--vm-icon-height": "18px",
          "--vm-icon-scale": 1,
        }}
        language={defaultLang}
        onVReady={onReady}
        // translations: undefined error after chaging src or back
        ref={player}
        onVTextTracksChange={setDefaultTextTrack}
        vPlaybackQualityChange={handlePlaybackQualityChange}
        onVPlaybackQualityChange={handlePlaybackQualityChange}
        onVAttachedChange={() => console.log("vAttachedChange")}
      >
        {/* Provider component is placed here. */}
        <VimeHls
          crossOrigin=''
          version='latest'
          config={hlsConfig}
          aspectRatio='16:9'
          vSrcSetChange={e => console.log("vSrcSetChange:", e)}
          onVSrcSetChange={e => console.log("onVSrcSetChange", e)}
          vMediaElChange={e => console.log("vMediaElChange:", e)}
          onVMediaElChange={e => console.log("onVMediaElChange:", e)}
        >
          <source data-src={url} key={url} type='application/x-mpegURL' />
        </VimeHls>
        <VimeDefaultUi noControls>
          {/* <VimeClickToPlay /> */}
          <VimeSpinner />

          <VideoMask />

          <VimeControls fullWidth waitForPlaybackStart>
            <VimeControlGroup>
              <VimeScrubberControl />
            </VimeControlGroup>

            <VimeControlGroup space='top'>
              <VimePlaybackControl />
              <VimeSeekButtonControl />
              <VimeVolumeControl />
              <VimeTimeProgress separator='/' />
              <VimeControlSpacer />
              {/* <VimeCaptionControl /> */}
              <VimeSettingsControl />
              <VimeFullscreenControl />
            </VimeControlGroup>
          </VimeControls>

          <VimeDefaultSettings />
        </VimeDefaultUi>
      </VimePlayer>
    );
  };

  return (
    <div>
      <Hls url={url} key={url} />
    </div>
  );
}
