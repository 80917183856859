export default {
  groups: [
    {
      id: 0,
      title: "最新影片",
      more_link: "",
      movies: [
        {
          id: 0,
          src: require("../../images/poster/天聲好手.jpg"),
          name: "天聲好手",
          others: "英文配音 | 普遍級",
        },
        {
          id: 1,
          src: require("../../images/poster/天錯之合.jpg"),
          name: "天錯之合",
          others: "英文配音 | 普遍級",
        },
        {
          id: 2,
          src: require("../../images/poster/昨日殺神.jpg"),
          name: "昨日殺神",
          others: "英文配音 | 普遍級",
        },
        {
          id: 3,
          src: require("../../images/poster/柴公園.jpg"),
          name: "柴公園",
          others: "英文配音 | 普遍級",
        },
        {
          id: 4,
          src: require("../../images/poster/町田君的世界.jpg"),
          name: "町田君的世界",
          others: "英文配音 | 普遍級",
        },
        {
          id: 5,
          src: require("../../images/poster/天聲好手.jpg"),
          name: "天聲好手",
          others: "英文配音 | 普遍級",
        },
      ],
    },
    {
      id: 4,
      layout: "img_topic",
      title: "精選專區",
      icon: "",
      more_link: true,
      topic_img: require("../../images/topic/topic2.png"),
      movies: [
        {
          id: 0,
          src: require("../../images/poster/天聲好手.jpg"),
          name: "天聲好手",
          others: "英文配音 | 普遍級",
        },
        {
          id: 1,
          src: require("../../images/poster/天錯之合.jpg"),
          name: "天錯之合",
          others: "英文配音 | 普遍級",
        },
        {
          id: 2,
          src: require("../../images/poster/昨日殺神.jpg"),
          name: "昨日殺神",
          others: "英文配音 | 普遍級",
        },
        {
          id: 3,
          src: require("../../images/poster/柴公園.jpg"),
          name: "柴公園",
          others: "英文配音 | 普遍級",
        },
        {
          id: 4,
          src: require("../../images/poster/町田君的世界.jpg"),
          name: "町田君的世界",
          others: "英文配音 | 普遍級",
        },
        {
          id: 5,
          src: require("../../images/poster/天聲好手.jpg"),
          name: "天聲好手",
          others: "英文配音 | 普遍級",
        },
      ],
    },
    {
      id: "hot",
      layout: "wide",
      title: "熱門影片",
      icon: "",
      more_link: false,
      movies: [
        {
          id: 0,
          src: require("../../images/poster/poster_wide_l1.png"),
          name: "雨傘學院",
          others: "西語配音 | 限制級",
        },
        {
          id: 1,
          src: require("../../images/poster/poster_wide_s1.png"),
          name: "不死軍團",
          others: "西語配音 | 限制級",
        },
        {
          id: 2,
          src: require("../../images/poster/poster_wide_s2.png"),
          name: "光譜發現愛",
          others: "西語配音 | 限制級",
        },
        {
          id: 3,
          src: require("../../images/poster/poster_wide_l2.png"),
          name: "ARRESTED DEVELOPMENT",
          others: "西語配音 | 限制級",
        },
        {
          id: 4,
          src: require("../../images/poster/poster_wide_s3.png"),
          name: "毒梟",
          others: "西語配音 | 限制級",
        },
        {
          id: 5,
          src: require("../../images/poster/poster_wide_s4.png"),
          name: "愛x死x機器人",
          others: "西語配音 | 限制級",
        },
      ],
    },
    {
      id: 1,
      title: "教育專區",
      link: "",
      movies: [
        {
          id: 0,
          src: require("../../images/poster/天聲好手.jpg"),
          name: "天聲好手",
          others: "英文配音 | 普遍級",
        },
        {
          id: 1,
          src: require("../../images/poster/天錯之合.jpg"),
          name: "天錯之合",
          others: "英文配音 | 普遍級",
        },
        {
          id: 2,
          src: require("../../images/poster/昨日殺神.jpg"),
          name: "昨日殺神",
          others: "英文配音 | 普遍級",
        },
        {
          id: 3,
          src: require("../../images/poster/柴公園.jpg"),
          name: "柴公園",
          others: "英文配音 | 普遍級",
        },
        {
          id: 4,
          src: require("../../images/poster/町田君的世界.jpg"),
          name: "町田君的世界",
          others: "英文配音 | 普遍級",
        },
        {
          id: 5,
          src: require("../../images/poster/天聲好手.jpg"),
          name: "天聲好手",
          others: "英文配音 | 普遍級",
        },
      ],
    },
    {
      id: 2,
      title: "動畫專區",
      link: "",
      movies: [
        {
          id: 0,
          src: require("../../images/poster/天聲好手.jpg"),
          name: "天聲好手",
          others: "英文配音 | 普遍級",
        },
        {
          id: 1,
          src: require("../../images/poster/天錯之合.jpg"),
          name: "天錯之合",
          others: "英文配音 | 普遍級",
        },
        {
          id: 2,
          src: require("../../images/poster/昨日殺神.jpg"),
          name: "昨日殺神",
          others: "英文配音 | 普遍級",
        },
        {
          id: 3,
          src: require("../../images/poster/柴公園.jpg"),
          name: "柴公園",
          others: "英文配音 | 普遍級",
        },
        {
          id: 4,
          src: require("../../images/poster/町田君的世界.jpg"),
          name: "町田君的世界",
          others: "英文配音 | 普遍級",
        },
        {
          id: 5,
          src: require("../../images/poster/天聲好手.jpg"),
          name: "天聲好手",
          others: "英文配音 | 普遍級",
        },
      ],
    },
    {
      id: 3,
      title: "推薦影片",
      icon: "camera",
      more_link: false,
      movies: [
        {
          id: 0,
          src: require("../../images/poster/天聲好手.jpg"),
          name: "天聲好手",
          others: "英文配音 | 普遍級",
        },
        {
          id: 1,
          src: require("../../images/poster/天錯之合.jpg"),
          name: "天錯之合",
          others: "英文配音 | 普遍級",
        },
        {
          id: 2,
          src: require("../../images/poster/昨日殺神.jpg"),
          name: "昨日殺神",
          others: "英文配音 | 普遍級",
        },
        {
          id: 3,
          src: require("../../images/poster/柴公園.jpg"),
          name: "柴公園",
          others: "英文配音 | 普遍級",
        },
        {
          id: 4,
          src: require("../../images/poster/町田君的世界.jpg"),
          name: "町田君的世界",
          others: "英文配音 | 普遍級",
        },
        {
          id: 5,
          src: require("../../images/poster/天聲好手.jpg"),
          name: "天聲好手",
          others: "英文配音 | 普遍級",
        },
      ],
    },

    {
      id: 5,
      layout: "seed",
      title: "視界播種",
      icon: "",
      more_link: false,
      movies: [
        {
          id: 0,
          src: require("../../images/poster/seed1.png"),
          link: "",
        },
        {
          id: 1,
          src: require("../../images/poster/seed2.png"),
          link: "",
        },
        {
          id: 2,
          src: require("../../images/poster/seed3.png"),
          link: "",
        },
        {
          id: 3,
          src: require("../../images/poster/seed4.png"),
          link: "",
        },
      ],
    },
  ],
};
