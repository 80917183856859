import React from "react";

// styles
import useStyles from "./styles";

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div>關於我們 | 聯絡我們</div>
        <div>© 2020 AV-JET All Rights Reserved.</div>
        <div>建議使用Chrome、Firefox、Safari瀏覽器，以獲得最佳瀏覽體驗。</div>
        <div>由於 Html5 語法支援度問題，無法使用 IE11 版本以下瀏覽器瀏覽。</div>
      </div>
    </div>
  );
}
