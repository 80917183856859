import React, { useEffect } from "react";
import Clappr from "clappr";
import { MediaControl } from "clappr";
// console.log(MediaControl, window.Clappr)
import LevelSelector from "level-selector";
import PlaybackRatePlugin from 'clappr-playback-rate-plugin'

export default function (props) {
  const { id, source } = props;
  useEffect(() => {
    let player = null;
    player = new Clappr.Player({
      parentId: `#${id}`,
      source: source,
      plugins: [MediaControl, LevelSelector, PlaybackRatePlugin],
      autoPlay: true,
      width: "100%",
      height: "520px",
      levelSelectorConfig: {
        title: "畫質",
        labels: {
          4: "h2",
          3: "H1",
          2: "High", // 500kbps
          1: "Med", // 240kbps
          0: "Low", // 120kbps
        },
        labelCallback: function (playbackLevel, customLabel) {
          return playbackLevel.level.height + "p"; // High 720p
        },
      },
      playback: {
        crossOrigin: 'anonymous', // Required if track loaded from another domain
        externalTracks: [
          {lang: 'en', label: 'English', src: 'https://static.playmedia-cdn.net/clappr/en.vtt'},
          {lang: 'fr', label: 'French', src: 'https://static.playmedia-cdn.net/clappr/fr.vtt'}
        ],
      },
    });
    return () => {
      player.destroy();
      player = null;
    };
  }, []);

  return <div id={id}></div>;
}

// import React from 'react';
// import Clappr from 'clappr';

// export default React.createClass({
//   propTypes: {
//     source: React.PropTypes.string
//   },

//   shouldComponentUpdate: function(nextProps, nextState) {
//     let changed = (nextProps.source != this.props.source);
//     this.props = nextProps;
//     this.state = nextState;
//     if (changed) {
//       this.change(nextProps.source);
//     }
//     return false;
//   },

//   componentDidMount: function() {
//     this.change(this.props.source);
//   },

//   componentWillUnmount: function() {
//     this.destroyPlayer();
//   },

//   destroyPlayer() {
//     if (this.player) {
//       this.player.destroy();
//     }
//     this.player = null;
//   },

//   change: function(source) {
//     if (this.player) {
//       this.destroyPlayer();
//     }
//     this.player = new Clappr.Player({
//       parent: this.refs.player,
//       source: source,
//       width: '100%',
//       height: '100%',
//       hlsjsConfig: {
//         enableWorker: true
//       }
//     });
//   },

//   render: function() {
//     return (
//       <div>
//         <div ref="player"></div>
//       </div>
//     );
//   }
// });
