import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Hidden,
  ListItem,
  List,
  Box,
  Typography,
  Grid,
  Divider,
} from "@material-ui/core";
import {
  Person as AccountIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import {
  useUserState,
  useUserDispatch,
  signOut,
} from "../../context/UserContext";

const navlink = [
  {
    id: 1,
    title: "購買方式",
    link: "#",
  },
  {
    id: 2,
    title: "關於我們",
    link: "#",
  },
  {
    id: 3,
    title: "視界播種",
    link: "#",
  },
];

export default function Header (props) {
  const classes = useStyles();

  // global
  const { isAuthenticated } = useUserState();

  React.useEffect(() => {
    if (props.changeStyleOnScroll) {
      window.addEventListener("scroll", headerStyleChange);
    }
    return function cleanup () {
      if (props.changeStyleOnScroll) {
        window.removeEventListener("scroll", headerStyleChange);
      }
    };
  });
  const headerStyleChange = () => {
    const { changeStyleOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeStyleOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes["sticky"]);
      document
        .getElementById("sticky-phantom")
        .classList.add(classes["stickyPhantomShow"]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes["sticky"]);
      document
        .getElementById("sticky-phantom")
        .classList.remove(classes["stickyPhantomShow"]);
    }
  };

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [profileMenu, setProfileMenu] = useState(null);
  var [isSearchOpen, setSearchOpen] = useState(false);

  return (
    <>
      <div id='sticky-phantom' className={classes.stickyPhantom}></div>
      <AppBar position='relative' className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.logoContainer}>
            <a href='#' title='首頁'>
              <img
                className={classes.logoImage}
                src={require("../../images/logos/logo3.png")}
                alt='logo'
              />
            </a>
          </div>

          <div className={classes.grow} />

          <div className={classNames(classes.search)}>
            <InputBase
              className={classNames(classes.searchInput, {
                [classes.searchFocused]: isSearchOpen,
              })}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              placeholder='搜尋電影/演員'
              inputProps={{
                "aria-label": "search",
              }}
              onMouseEnter={() => setSearchOpen(true)}
              onMouseLeave={() => setSearchOpen(false)}
            />
            <IconButton
              type='submit'
              className={classes.searchIcon}
              aria-label='search'
              onMouseEnter={() => setSearchOpen(true)}
              onMouseLeave={() => setSearchOpen(false)}
            >
              <SearchIcon />
            </IconButton>
          </div>

          <Hidden smDown>
            <List className={classes.navigationContainer}>
              {navlink.map((page, i) => (
                <div key={i}>
                  <ListItem
                    button
                    component='a'
                    href={page.link}
                    className={classes.listItem}
                  >
                    <Typography
                      variant='subtitle2'
                      color='textPrimary'
                      className={classNames(classes.listItemText, "menu-item")}
                    >
                      <Box>{page.title}</Box>
                    </Typography>
                  </ListItem>
                </div>
              ))}
            </List>
          </Hidden>

          <List
            className={classNames(classes.navigationContainer, {
              [classes.hide]: isAuthenticated,
            })}
          >
            <ListItem
              button
              component='a'
              href='#login'
              className={classes.signupButton}
              onClick={() => userDispatch({ type: "LOGIN_SUCCESS" })}
            >
              <Typography
                variant='subtitle2'
                className={classNames("menu-item")}
              >
                <Box>註冊/登入</Box>
              </Typography>
            </ListItem>
          </List>

          <IconButton
            aria-haspopup='true'
            color='inherit'
            className={classNames(classes.headerMenuButton, classes.listItem, {
              [classes.hide]: !isAuthenticated,
            })}
            aria-controls='profile-menu'
            onClick={e => setProfileMenu(e.currentTarget)}
          >
            <AccountIcon classes={{ root: classes.headerIcon }} />
            <Typography variant='subtitle2' className={classes.listItemText}>
              測試帳號
            </Typography>
          </IconButton>
          <div className={classes.profileMenuUser}>
            <Menu
              id='profile-menu'
              open={Boolean(profileMenu)}
              anchorEl={profileMenu}
              onClose={() => setProfileMenu(null)}
              className={classes.headerMenu}
              classes={{ paper: classes.profileMenu }}
              disableAutoFocusItem
            >
              <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                <Grid
                  container
                  direction='column'
                  justify='center'
                  alignItems='flex-start'
                >
                  <Grid item xs={12}>
                    <Box className={classes.profileSubTitle}> 帳戶資訊 </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={classes.profileSubBody}>
                      截止日: 2024/09/12
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={classes.profileSubBody}>
                      空間剩餘: 23/50
                    </Box>
                  </Grid>
                </Grid>
              </MenuItem>

              <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                觀看紀錄/收藏
              </MenuItem>
              <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                續訂服務
              </MenuItem>
              <Divider variant='middle' />
              <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
                onClick={() => signOut(userDispatch, props.history)}
              >
                <Box>登出</Box>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}
