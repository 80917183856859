import React from "react";
import PropTypes from "prop-types";
import { Typography, Grid, Box, Button } from "@material-ui/core";
import classNames from "classnames";

import useStyles from "./styles";
import { ReactComponent as CameraIcon } from "../../images/icons/camera.svg";
import MovieAlbumItem from "../MovieAlbumItem";
import PlayButton from "../PlayButton";

const MovieAlbum = props => {
  const classes = useStyles();
  const {
    item,
    hideTitleIcon,
    hideMoreLink,
    hoverMaskMode,
    hoverEnlargePoster,
    extraMarginBottom,
  } = props;
  const firstFourMovies = item.movies.slice(0, 4);

  const DefaultLayout = () => {
    const moviesLen = item.movies.length;
    return (
      <>
        {item.movies.map((movie, index) => (
          <Grid item xs={4} md={2} key={`${movie.id}`}>
            <MovieAlbumItem
              key={`album-item-${movie.id}`}
              name={movie.name}
              item={movie}
              hoverMaskMode={hoverMaskMode}
              hoverEnlargePoster={hoverEnlargePoster}
              extraMarginBottom={extraMarginBottom}
            ></MovieAlbumItem>
          </Grid>
        ))}
      </>
    );
  };

  const ImgTopicLayout = () => {
    return (
      <>
        <Box className={classes.topicContainer}>
          <Box className={classes.topicPoster}>
            <img
              src={item.topic_img}
              alt={item.name}
              className={classNames(classes.imgFluid, classes.topicImg)}
            ></img>
          </Box>
          <Box className={classes.topicMovies}>
            {firstFourMovies.map(movie => (
              <Box
                className={classes.topicMovie}
                key={`album-item-${movie.id}`}
              >
                <MovieAlbumItem
                  name={movie.name}
                  item={movie}
                  showMeta={false}
                  showTitle={false}
                ></MovieAlbumItem>
              </Box>
            ))}
          </Box>
        </Box>
      </>
    );
  };

  const HoverMask = ({ size = "sm", showDesceiption } = props) => {
    const wideMaskTitleClass =
      size === "lg" ? classes.wideMaskLargeTitle : classes.wideMaskSmallTitle;
    const metaTextClass =
      size === "lg" ? classes.metaLargeText : classes.metaText;
    const buttonMarginTop = size === "lg" ? 4 : 3;
    return (
      <>
        <div
          className={classNames(classes.wideMask, {
            [classes.wideMaskSmall]: size !== "lg",
          })}
        >
          <Grid container>
            <Grid item xs={12}>
              <div className={wideMaskTitleClass}>{item.movies[0].name}</div>
            </Grid>
            <Grid item xs={12}>
              <div
                className={classNames(metaTextClass, {
                  [classes.metaContainer]: size === "lg",
                })}
              >
                西語配音 | 限制級
              </div>
            </Grid>
            <Grid item xs={12}>
              <Box
                className={classes.blockWithText2}
                display={size === "lg" || showDesceiption ? "block" : "none"}
              >
                毒梟是一部美國犯罪網路電視劇，由克里斯·布蘭卡托、Carlo
                Bernard和道格·米羅創作和監製。描述二十世紀八零年代哥倫比亞大毒梟巴勃羅·埃斯科瓦爾，是怎麼靠著自身的聰明及手段......
              </Box>
            </Grid>
            <Grid container item justify='center'>
              <Grid item>
                <Box
                  className={classNames(
                    { [classes.playButtonContainerLg]: size === "lg" },
                    { [classes.playButtonContainerSm]: size === "sm" },
                  )}
                >
                  <PlayButton size={size} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  const WidePosterLayout = () => (
    <>
      <Box className={classes.widePosterContainer}>
        <Box className={classes.widePosterChild}>
          <Box className={classes.largeWideContainer}>
            <Box position='relative'>
              <img
                src={item.movies[0].src}
                alt={item.movies[0].name}
                className={classes.largeWidePoster}
              />
              <HoverMask size='lg' />
            </Box>
          </Box>
          <Box className={classes.smallWideContainer}>
            <Box className={classes.smallWideContent}>
              <Box position='relative'>
                <img
                  src={item.movies[1].src}
                  alt={item.movies[1].name}
                  className={classes.smallWidePoster}
                />
                <HoverMask />
              </Box>
            </Box>
            <Box className={classes.smallWideContent}>
              <Box position='relative'>
                <img
                  src={item.movies[2].src}
                  alt={item.movies[2].name}
                  className={classes.smallWidePoster}
                />
                <HoverMask />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.widePosterChild}>
          <Box className={classes.smallWideContainer}>
            <Box className={classes.smallWideContent}>
              <Box position='relative'>
                <img
                  src={item.movies[4].src}
                  alt={item.movies[4].name}
                  className={classes.smallWidePoster}
                />
                <HoverMask />
              </Box>
            </Box>
            <Box className={classes.smallWideContent}>
              <Box position='relative'>
                <img
                  src={item.movies[5].src}
                  alt={item.movies[5].name}
                  className={classes.smallWidePoster}
                />
                <HoverMask />
              </Box>
            </Box>
          </Box>
          <Box className={classes.largeWideContainer}>
            <Box position='relative'>
              <img
                src={item.movies[3].src}
                alt={item.movies[3].name}
                className={classes.largeWidePoster}
              />
              <HoverMask size='lg' />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );

  const SeedLayout = () => (
    <>
      {item.movies.map(movie => (
        <Grid item xs={3} key={movie.id}>
          <a href={movie.link}>
            <img
              src={movie.src}
              alt={movie.name}
              className={classNames(
                classes.smallWidePoster,
                classes.lightBackground,
              )}
            ></img>
          </a>
        </Grid>
      ))}
    </>
  );

  const renderList = item => {
    if (item.layout === "img_topic") {
      return <ImgTopicLayout />;
    } else if (item.layout === "seed") {
      return <SeedLayout />;
    } else if (item.layout === "wide") {
      return <WidePosterLayout />;
    }
    return <DefaultLayout />;
  };

  return (
    <div>
      <Grid
        container
        direction='row'
        justify='space-between'
        alignItems='flex-end'
        spacing={1}
      >
        <Grid item>
          <Box className={classes.title}>
            {item.title ? (
              <CameraIcon
                display={hideTitleIcon || item.icon === "" ? "none" : "inherit"}
                style={{ marginRight: 8 }}
              />
            ) : (
              ""
            )}
            <Typography variant='h1'>{item.title}</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box display={hideMoreLink || item.more_link === false ? "none" : ""}>
            <Button className={classes.moreButton} href='#/movie/list'>
              看全部
            </Button>
          </Box>
        </Grid>
      </Grid>
      <div className={classes.movieList}>
        <Grid container direction='row' alignItems='flex-end' sapcing={1}>
          {renderList(item)}
        </Grid>
      </div>
    </div>
  );
};

MovieAlbum.defaultProps = {
  hideTitleIcon: false,
  hideMoreLink: false,
  hoverEnlargePoster: false,
  extraMarginBottom: false,
};

MovieAlbum.propsTypes = {
  item: PropTypes.array.isRequired,
  hideTitleIcon: PropTypes.bool,
  hideMoreLink: PropTypes.bool,
  hoverMaskMode: PropTypes.bool,
  hoverEnlargePoster: PropTypes.bool,
  extraMarginBottom: PropTypes.bool,
};

export default MovieAlbum;
