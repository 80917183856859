import React from "react";
import PropTypes from "prop-types";
import { Grid, Paper } from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import VimeJsPlayer from "../../components/VimeJsPlayer";
import ClapprPlayer from "../../components/ClapprPlayer";
import MovieDescription from "../../components/MovieDescription";
import MovieAlbum from "../../components/MovieAlbum";

import mock from "../../components/MovieContainer/mock";

// context
import { useVideoState } from "../../context/VideoContext";

const MovieDetail = () => {
  const classes = useStyles();
  const { url } = useVideoState();

  const recommends = mock.groups.filter(e => e.title === "推薦影片")[0];
  const isIE =
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    navigator.appVersion.indexOf("Trident/") > -1;
  const Player = () => {
    if (isIE) {
      return <ClapprPlayer id='video1' source={url} />;
    }
    return <VimeJsPlayer url={url} />;
  };

  return (
    <>
      <div className={classes.movieDetailContainer}>
        <Grid
          container
          spacing={4}
          direction='row'
          alignItems='center'
          justify='center'
        >
          <Grid item xs={12}>
            <div className={classes.playerContainer}>
              <div className={classes.player}>
                <Player />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.movieDescriptionContainer}>
              <MovieDescription />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.recommend}>
              <MovieAlbum item={recommends} hoverMaskMode='simple' />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

MovieDetail.defaultProps = {};

MovieDetail.propsTypes = {};

export default MovieDetail;
