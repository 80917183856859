import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, Typography } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {},
    content: {
      color: theme.palette.common.grey[500],
    },
    button: theme.typography.subtitle2,
    default: {
      color: 'inherit'
    },
    active: {
      color: "black",
    },
  }),
);

const VideoListSortingButton = prpos => {
  const classes = useStyles();

  const types = ["最多觀看", "最新影片", "最多收藏"];

  const [typeState, setTypeState] = useState(0);
  return (
    <>
      <div className={classes.root}>
        <div className={classes.content}>
          <Grid container justify='center' alignItems='center'>
            <Grid item>
              <Button
                onClick={() => setTypeState(0)}
                className={classNames(classes.button, classes.default, {
                  [classes.active]: typeState === 0,
                })}
              >
                {types[0]}
              </Button>
            </Grid>
            <Grid item>
              <div>|</div>
            </Grid>
            <Grid item>
              <Button
                onClick={() => setTypeState(1)}
                className={classNames(classes.button, classes.default, {
                  [classes.active]: typeState === 1,
                })}
              >
                {types[1]}
              </Button>
            </Grid>
            <Grid item>
              <div>|</div>
            </Grid>
            <Grid item>
              <Button
                onClick={() => setTypeState(2)}
                className={classNames(classes.button, classes.default, {
                  [classes.active]: typeState === 2,
                })}
              >
                {types[2]}
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

VideoListSortingButton.defaultProps = {};

VideoListSortingButton.propsTypes = {};

export default VideoListSortingButton;
