const en = {
  play: "Play",
  pause: "Pause",
  playback: "Playback",
  scrubber: "Scrubber",
  scrubberLabel: "{currentTime} of {duration}",
  played: "Played",
  duration: "Duration",
  buffered: "Buffered",
  close: "Close",
  currentTime: "Current time",
  live: "LIVE",
  volume: "Volume",
  mute: "Mute",
  unmute: "Unmute",
  captions: "Captions",
  subtitlesOrCc: "Subtitles/CC",
  enableCaptions: "Enable subtitles/captions",
  disableCaptions: "Disable subtitles/captions",
  auto: "Auto",
  fullscreen: "Fullscreen",
  enterFullscreen: "Enter fullscreen",
  exitFullscreen: "Exit fullscreen",
  settings: "Settings",
  seekForward: "Seek forward",
  seekBackward: "Seek backward",
  seekTotal: "Seek total",
  normal: "Normal",
  none: "None",
  playbackRate: "Playback Rate",
  playbackQuality: "Playback Quality",
  loop: "Loop",
  disabled: "Disabled",
  off: "Off",
  enabled: "Enabled",
  pip: "Picture-in-Picture",
  enterPiP: "Miniplayer",
  exitPiP: "Expand",
};

// Chinese translation.
const zh = {
  play: "播放",
  pause: "暫停",
  playback: "Playback",
  scrubber: "Scrubber",
  scrubberLabel: "{currentTime} of {duration}",
  played: "Played",
  duration: "Duration",
  buffered: "Buffered",
  close: "關閉",
  currentTime: "Current time",
  live: "LIVE",
  volume: "音量",
  mute: "靜音",
  unmute: "取消靜音",
  captions: "字幕",
  subtitlesOrCc: "字幕",
  enableCaptions: "啟用字幕",
  disableCaptions: "停用字幕",
  auto: "自動",
  fullscreen: "全螢幕",
  enterFullscreen: "全螢幕",
  exitFullscreen: "離開全螢幕",
  settings: "設定",
  seekForward: "快轉",
  seekBackward: "倒轉",
  seekTotal: "Seek total",
  normal: "正常",
  none: "無",
  playbackRate: "播放速度",
  playbackQuality: "畫質",
  loop: "循環播放",
  disabled: "停用",
  off: "關閉",
  enabled: "啟用",
  pip: "Picture-in-Picture",
  enterPiP: "Miniplayer",
  exitPiP: "Expand",
};

export { en, zh };
