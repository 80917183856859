import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles } from "@material-ui/core/styles";

// components
import PagSwiper from "../../components/Swiper";
import MovieMenu from "../../components/MovieMenu";
import MovieContainer from "../../components/MovieContainer";

const useStyles = makeStyles(theme =>
  createStyles({
    content: {
      margin: "6.5%",
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    menu: {
      marginTop: theme.spacing(3),
      marginLeft: "6.5%",
      marginRight: "6.5%",
    },
  }),
);

function MovieHome (props) {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={0} justify='center'>
        <Grid item xs={12}>
          <div className={classes.menu}>
            <MovieMenu />
          </div>
        </Grid>
        <Grid item xs={12}>
          <PagSwiper />
        </Grid>

        <Grid item xs={12}>
          <div className={classes.content}>
            <MovieContainer />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default MovieHome;
