import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import mock from "./mock";

import MovieAlbum from "../MovieAlbum";

const useStyles = makeStyles(theme =>
  createStyles({
    album: {
      marginBottom: theme.spacing(5),
    },
  }),
);

export default function MovieContainer (props) {
  const classes = useStyles();

  return (
    <div>
      {mock.groups.map(group => {
        return (
          <div className={classes.album} key={`group-${group.id}`}>
            <MovieAlbum item={group} hideTitleIcon={true} hoverEnlargePoster={true}></MovieAlbum>
          </div>
        );
      })}
    </div>
  );
}
