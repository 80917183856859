import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  album: {
    height: 500,
  },
  title: {
    display: "inline-flex",
    alignItems: "center",
  },
  moreButton: {
    ...theme.typography.subtitle1,
    fontWeight: 700,
    color: "#252e78",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  widePosterContainer: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 5,
    paddingLeft: 0,
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
  },
  widePosterChild: {
    flex: "50%",
    maxWidth: "50%",
    margin: 5,
    "&:first-child": {
      marginLeft: 0,
    },

    [theme.breakpoints.down("sm")]: {
      flex: "100%",
      maxWidth: "100%",
    },
  },
  smallWideContainer: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  largeWideContainer: {
    flex: "100%",
    maxWidth: "100%",
    padding: "0 4px",
  },
  smallWideContent: {
    flex: "50%",
    maxWidth: "50%",
    padding: "0 4px",
    [theme.breakpoints.down("sm")]: {
      flex: "50%",
      maxWidth: "50%",
    },
  },
  topicImg: {
    marginRight: 5,
    overflow: "hidden",
    [theme.breakpoints.up("lg")]: {
      maxHeight: 282,
    },
  },
  movieList: {
    marginTop: theme.spacing(1.6),
    paddingLeft: 0,
  },
  largeWidePoster: {
    width: "42vw",
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      maxWidth: 620,
      maxHeight: 372,
    },
    [theme.breakpoints.down("sm")]: {
      width: "95vw",
    },
  },
  smallWidePoster: {
    width: "24vw",
    overflow: "hidden",
    opacity: 1,
    transition: ".5s ease",
    "&:first-child": {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 300,
      maxHeight: 180,
    },
    [theme.breakpoints.down("lg")]: {
      width: "20.5vw",
    },
    [theme.breakpoints.down("sm")]: {
      width: "46vw",
    },
  },
  lightBackground: {
    "&:hover": {
      opacity: 0.7,
    },
  },
  wideMask: {
    opacity: 0,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.65)",
    color: "white",
    padding: "5%",
    paddingBottom: 0,
    marginBottom: 10,
    transitionDuration: "0.5s",
    "&:hover": {
      opacity: 1,
    },
  },
  wideMaskSmall: {
    padding: "5%",
  },
  wideMaskLargeTitle: {
    ...theme.typography.h2,
    fontWeight: 500,
  },
  wideMaskSmallTitle: {
    ...theme.typography.caption,
    fontSize: 24,
    fontWeight: 700,
  },
  metaContainer: {
    marginTop: 12,
  },
  metaLargeText: {
    ...theme.typography.tags,
    color: theme.palette.common.grey[300],
    marginBottom: theme.spacing(0.75),
  },
  metaText: {
    ...theme.typography.caption,
    color: theme.palette.common.grey[300],
    marginBottom: theme.spacing(0.75),
    fontSize: 14,
  },
  blockWithText: {
    width: "28rem",
    maxWidth: "34rem",
    overflow: "hidden",
    position: "relative",
    lineHeight: "1.2em",
    maxHeight: "3.6em",
    textAlign: "justify",
    marginRight: "-1em",
    paddingRight: "1em",
    marginBottom: "0.5em",
    "&&:before": {
      content: "'......'",
      position: "absolute",
      right: 0,
      bottom: 0,
    },
    "&&:after": {
      content: "''",
      position: "absolute",
      right: 0,
      width: "1em",
      height: "1em",
      marginTop: "0.2em",
      background: "transparent",
    },
  },
  blockWithText2: {
    maxWidth: "34rem",
    overflow: "hidden",
    position: "relative",
    lineHeight: "1.2em",
    maxHeight: "3.6em",
    textAlign: "justify",
    marginRight: "-1em",
    paddingRight: "1em",
    marginBottom: "0.5em",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "50rem",
    },
  },
  playButtonContainerLg: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.only("md")]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(10),
    },
    [theme.breakpoints.only("xs")]: {
      marginTop: theme.spacing(0.75),
    },
  },
  playButtonContainerSm: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.only("lg")]: {
      marginTop: theme.spacing(2.5),
    },
    [theme.breakpoints.only("md")]: {
      marginTop: theme.spacing(0.5),
    },
    [theme.breakpoints.only("xs")]: {
      marginTop: theme.spacing(0.15),
    },
  },
  topicContainer: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: 5,
    paddingLeft: 0,
  },
  topicPoster: {
    flex: "30%",
    position: "relative",
    marginRight: 5,
    marginBottom: 8,
  },
  topicMovies: {
    flex: "67%",
    display: "flex",
  },
  topicMovie: {
    flex: "25%",
    maxWidth: 200,
  },
  imgFluid: {
    maxWidth: "100%",
    height: "auto",
    transition: "transform .2s",
  },
}));
