import React, { useState, useRef } from "react";

import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Popover, Typography, List, ListItem, Hidden } from "@material-ui/core";
import classNames from "classnames";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// styles
import useStyles from "./styles";

const LinkTabs = withStyles(theme => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

const LinkTab = withStyles(theme => ({
  root: {
    ...theme.typography.body2,
    textTransform: "none",
    minWidth: 115,
    marginRight: theme.spacing(1.75),
    color: theme.palette.common.grey[900],
    "&:hover": {
      color: theme.palette.common.black,
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.common.black,
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: theme.palette.common.black,
    },
  },
  selected: {},
}))(Tab);

const menus = [
  {
    id: 0,
    name: "所有影片",
  },
  {
    id: 1,
    name: "國片",
  },
  {
    id: 2,
    name: "亞洲片",
  },
  {
    id: 3,
    name: "歐美片",
  },
  {
    id: 4,
    name: "紀錄片",
  },
  {
    id: 5,
    name: "動畫片",
  },
  {
    id: 6,
    name: "康軒學習專區",
  },
  {
    id: 7,
    name: "台北市線上資料庫",
  },
  {
    id: 8,
    name: "所有分類",
  },
];

const pages = {
  all: {
    title: "所有分類",
    id: "all-pages",
    children: {
      recent: {
        groupTitle: "新上映",
        pages: [
          {
            title: "本月新片",
            href: "/movie/list",
          },
          {
            title: "本月主題名單",
            href: "/movie/list",
          },
        ],
      },
      categories: {
        groupTitle: "影片",
        pages: [
          {
            title: "國片",
            href: "/desktop-app",
          },
          {
            title: "亞洲片",
            href: "/mobile-app",
          },
          {
            title: "歐美片",
            href: "/mobile-app",
          },
          {
            title: "紀錄片",
            href: "/mobile-app",
          },
          {
            title: "動畫片",
            href: "/mobile-app",
          },
        ],
      },
      partner: {
        groupTitle: "主題專區",
        pages: [
          {
            title: "康軒學習專區",
            href: "/home",
          },
          {
            title: "台北市線上資料庫",
            href: "/web-basic",
          },
          {
            title: "2018共同玩創動畫賞",
            href: "/service",
          },
          {
            title: "看見家鄉系列",
            href: "/startup",
          },
          {
            title: "電影策展與教學工作坊",
            href: "/enterprise",
          },
        ],
      },
    },
  },
};

export default function MovieMenu (props) {
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef(null);

  const popoverEnter = ({ currentTarget }) => {
    setOpenedPopover(true);
  };

  const popoverLeave = ({ currentTarget }) => {
    setOpenedPopover(false);
  };

  const open = Boolean(anchorEl);

  const MenuGroup = props => {
    const { item } = props;
    return (
      <List disablePadding>
        <ListItem disableGutters>
          <Typography
            variant='subtitle2'
            color='primary'
            className={classes.menuGroupTitle}
          >
            {item.groupTitle}
          </Typography>
        </ListItem>
        {item.pages.map((page, i) => (
          <ListItem disableGutters key={i} className={classes.menuGroupItem}>
            <Typography
              variant='caption'
              component={"a"}
              href={page.href}
              className={classNames(classes.navLink, "submenu-item")}
              onClick={handleClose}
            >
              {page.title}
            </Typography>
          </ListItem>
        ))}
      </List>
    );
  };

  const AllPages = () => {
    const { recent, categories, partner } = pages.all.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={recent} />
        </div>
        <div className={classes.menuItem}>
          <MenuGroup item={categories} />
        </div>
        <div className={classes.menuItem}>
          <MenuGroup item={partner} />
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className={classes.root}>
        <div>
          <LinkTabs
            value={value}
            onChange={handleChange}
            variant='scrollable'
            scrollButtons='on'
          >
            {menus.map(menu =>
              menu.name === "所有分類" ? (
                <LinkTab
                  ref={popoverAnchor}
                  key={menu.name}
                  className={classes.tab}
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup='true'
                  label={menu.name}
                  component={Link}
                  to='/movie/list'
                  onMouseEnter={popoverEnter}
                  onMouseLeave={popoverLeave}
                />
              ) : (
                <LinkTab
                  key={menu.name}
                  className={classes.tab}
                  label={menu.name}
                  component={Link}
                  to='/movie/list'
                />
              ),
            )}
          </LinkTabs>
          <Popover
            elevation={0}
            id='mouse-over-popover'
            className={classes.popover}
            classes={{ paper: classes.popoverContent }}
            open={openedPopover}
            anchorEl={popoverAnchor.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={handleClose}
            disableRestoreFocus
            PaperProps={{
              onMouseEnter: popoverEnter,
              onMouseLeave: popoverLeave,
            }}
          >
            <div>{<AllPages />}</div>
          </Popover>
        </div>
      </div>
    </div>
  );
}
