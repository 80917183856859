import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    display: "flex",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.6)",
    pointerEvents: "none",
  },
  maskPlayButton: {
    ...theme.typography.subtitle1,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 24,
    paddingRight: 24,
    height: 40,
    borderRadius: 30,
    pointerEvents: "auto",
    boxShadow: "0 0",
  },
  maskPlayText: {
    ...theme.typography.subtitle1,
    color: "white",
    textDecoration: "underline",
    pointerEvents: "auto",
    fontSize: 16,
  },
  hide: {
    display: "none",
  },
  geoError: {
    background: "black",
  },
}));
