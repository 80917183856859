import React from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { VimeClickToPlay } from "@vime/react";

import PauseMask from "../PauseMask";
import VideoHeader from "../VideoHeader";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: "flex",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      /* 
          Why 21? Simply because it's one index above the `ClickToPlay` component. Click the link
          below and scroll down to the `Z-Index` section to see existing levels.
          @see https://github.com/vime-js/vime/blob/master/packages/core/src/globals/themes/default.css
      */
      zIndex: 21,
      pointerEvents: "auto",
      "& > $header": {
        opacity: 0,
      },
      "&:hover > $header": {
        opacity: 1,
        zIndex: 23,
      },
    },
    pauseMask: {
      zIndex: 22,
    },
    header: {},
  }),
);

const VideoMask = prpos => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <div className={classes.pauseMask}>
          <PauseMask />
        </div>
        <div className={classes.header}>
          <VideoHeader />
        </div>
        <VimeClickToPlay />
      </div>
    </>
  );
};

VideoMask.defaultProps = {};

VideoMask.propsTypes = {};

export default VideoMask;
