import React from "react";

var VideoStateContext = React.createContext();
var VideoDispatchContext = React.createContext();
const movieUrl =
  "https://videodelivery.net/3bd1d87dcf7710e52865e7ba0ae147f4/manifest/video.m3u8";
const trailerUrl =
  "https://videodelivery.net/92bc0f300c930ba0eb7123d746383392/manifest/video.m3u8";

function videoReducer (state, action) {
  switch (action.type) {
    case "PLAY_TRAILER_SUCCESS":
      return { ...state, videoType: "trailer", url: trailerUrl };
    case "PLAY_MOVIE_SUCCESS":
      return { ...state, videoType: "movie", url: movieUrl };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function VideoProvider ({ children }) {
  var [state, dispatch] = React.useReducer(videoReducer, {
    videoType: "trailer",
    url: trailerUrl,
  });

  return (
    <VideoStateContext.Provider value={state}>
      <VideoDispatchContext.Provider value={dispatch}>
        {children}
      </VideoDispatchContext.Provider>
    </VideoStateContext.Provider>
  );
}

function useVideoState () {
  var context = React.useContext(VideoStateContext);
  if (context === undefined) {
    throw new Error("useVideoState must be used within a VideoProvider");
  }
  return context;
}

function useVideoDispatch () {
  var context = React.useContext(VideoDispatchContext);
  if (context === undefined) {
    throw new Error("useVideoDispatch must be used within a VideoProvider");
  }
  return context;
}

export {
  VideoProvider,
  useVideoState,
  useVideoDispatch,
  handleMaskTopButton,
  handleMaskBottomButton,
};

// ###########################################################

function handleMaskTopButton (player) {
  player.play();
}

async function handleMaskBottomButton (dispatch, isLogin, isTrailer) {
  if (isLogin) {
    if (isTrailer) {
      dispatch({ type: "PLAY_MOVIE_SUCCESS" });
    } else {
      dispatch({ type: "PLAY_TRAILER_SUCCESS" });
    }
  }
}
