import React from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import classNames from "classnames";

// context
import { useUserDispatch } from "../../context/UserContext";

const useStyles = makeStyles(theme =>
  createStyles({
    dialogPaper: {
      padding: theme.spacing(1),
    },
    dialogTitle: {
      ...theme.typography.subtitle1,
      fontWeight: 500,
    },
    dialogDescription: {
      ...theme.typography.smallCaption,
    },
    dialogButton: {
      ...theme.typography.caption,
      fontWeight: 700,
    },
  }),
);

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const LoginAlertDialog = props => {
  const classes = useStyles();
  const userDispatch = useUserDispatch();
  const { open, setOpen } = props;

  const handleDialogOpen = () => {
    if (setOpen) {
      setOpen(true);
    }
  };

  const handleDialogClose = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  return (
    <div>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle
          id='alert-dialog-slide-title'
          className={classes.dialogTitle}
        >
          {"使用進階功能？"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-slide-description'
            className={classes.dialogDescription}
          >
            您需要加入飛行會員以使用進階功能。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            color='primary'
            className={classes.dialogButton}
          >
            返回
          </Button>
          <Button
            onClick={() => userDispatch({ type: "LOGIN_SUCCESS" })}
            color='primary'
            className={classes.dialogButton}
            href='#login'
          >
            登入/註冊
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

LoginAlertDialog.defaultProps = {};

LoginAlertDialog.propsTypes = {};

export default LoginAlertDialog;
