import { makeStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default makeStyles(theme => ({
  logoContainer: {
    width: 240,
    maxHeight: 48,
  },
  logoImage: {
    width: "100%",
    height: "100%",
  },
  logotype: {
    color: "black",
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    fontWeight: 500,
    fontSize: 18,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  appBar: {
    width: "100vw",
    height: 80,
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "0 5px 15px 0 rgba(191, 191, 191, 0.25)",
    backgroundColor: "#ffffff",
    position: "relative",
    justifyContent: "center",
  },
  sticky: {
    position: "fixed!important",
    top: 0,
    width: "100%",
  },
  stickyPhantom: {
    width: "100vw",
    height: 80,
    position: "relative",
    display: "none",
  },
  stickyPhantomShow: {
    display: "block",
  },
  toolbar: {
    paddingLeft: "6.5%",
    paddingRight: "6.5%",
  },
  hide: {
    display: "none!important",
  },
  grow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: theme.spacing(3),
  },
  listItem: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  listItemText: {
    flex: "0 0 auto",
    whiteSpace: "nowrap",
    color: "#252e78",
    fontWeight: 700,
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  search: {
    ...theme.typography.subtitle2,
    display: "inline-flex",
    position: "relative",
    paddingLeft: theme.spacing(2.5),
    width: 200,
    height: 40,
    borderRadius: 30,
  },
  searchInput: {
    opacity: 0,
    backgroundColor: fade(theme.palette.common.black, 0),
    transition: theme.transitions.create(["background-color", "width"]),
    "&:hover": {
      cursor: "pointer",
      backgroundColor: fade(theme.palette.common.black, 0.08),
    },
  },
  searchFocused: {
    backgroundColor: fade(theme.palette.common.black, 0.08),
    opacity: 1,
  },
  searchIcon: {
    width: 36,
    right: theme.spacing(1),
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: theme.transitions.create("right"),
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
    },
  },
  searchIconOpened: {
    right: theme.spacing(1.25),
  },
  searchIconOutside: {
    width: 68,
    height: 36,
    right: 0,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: theme.transitions.create("right"),
    "&:hover": {
      cursor: "pointer",
    },
    borderRadius: 25,
  },
  inputRoot: {
    color: "inherit",
    display: "flex",
    borderRadius: 25,
  },
  inputInput: {
    ...theme.typography.subtitle2,
    color: "black",
    paddingLeft: theme.spacing(5),
  },
  messageContent: {
    display: "flex",
    flexDirection: "column",
  },
  signupButton: {
    minWidth: 96,
    maxWidth: 106,
    borderRadius: 30,
    color: "white",
    backgroundColor: theme.palette.common.special.main,
    "&:hover": {
      backgroundColor: theme.palette.common.special.dark,
    },
  },
  signupButtonText: {
    fontWeight: 700,
  },
  headerMenu: {
    marginTop: theme.spacing(7),
  },
  headerMenuList: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenuItem: {
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  headerMenuButton: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
  headerMenuButtonCollapse: {
    marginRight: theme.spacing(2),
  },
  headerIcon: {
    fontSize: 28,
    // color: "rgba(255, 255, 255, 0.35)",
    color: "black",
  },
  headerIconCollapse: {
    color: "white",
  },
  headerIconOutside: {
    fontSize: 28,
    color: "rgba(255, 255, 255, 1)",
  },
  profileMenu: {
    minWidth: 148,
    boxShadow: "0 5px 5px 0 rgba(184, 184, 184, 0.15)",
    borderRadius: 0,
  },
  profileMenuUser: {
    display: "flex",
    flexDirection: "column",
    // padding: theme.spacing(1),
    // boxShadow: "0 5px 5px 0 rgba(184, 184, 184, 0.15)",
  },
  profileMenuItem: {
    ...theme.typography.subtitle2,
    color: theme.palette.common.grey[600],
  },
  profileMenuIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.hint,
  },
  profileMenuLink: {
    fontSize: 16,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  profileSub: {
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      cursor: "pointer",
    },
  },
  profileSubTitle: {
    ...theme.typography.subtitle2,
    // color: "black",
  },
  profileSubBody: {
    ...theme.typography.smallCaption,
    // color: "black",
  },
  messageNotification: {
    height: "auto",
    display: "flex",
    alignItems: "center",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light,
    },
  },
  messageNotificationSide: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  messageNotificationBodySide: {
    alignItems: "flex-start",
    marginRight: 0,
  },
  sendMessageButton: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textTransform: "none",
  },
  sendButtonIcon: {
    marginLeft: theme.spacing(2),
  },
  white: {
    border: "0",
    padding: "0.625rem 0",
    marginBottom: "20px",
    color: "#555",
    backgroundColor: "#fff !important",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
  },
  userName: {
    color: "#252e78",
  },
}));
