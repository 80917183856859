import React from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Box, Typography, Grid } from "@material-ui/core";

import VideoTagButton from "../../components/VideoTagButton";
import mock from "./mock";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      margin: "6.5%",
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
    mainImage: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
    keyword: {
      display: "inline-block",
      color: theme.palette.common.special.main,
    },
    tagsContainer: {
      marginTop: theme.spacing(13.625),
    },
    tags: {
      marginTop: theme.spacing(3.5),
    },
    recommend: {
      marginTop: theme.spacing(7.25),
    },
    recommendList: {
      marginTop: theme.spacing(3),
    },
    recommendImg: {
      width: 300,
      height: 180,
    },
    lightBackground: {
      "&:hover": {
        opacity: 0.7,
      },
    },
  }),
);

const SearchNotFound = prpos => {
  const classes = useStyles();

  const mainImage = require("../../images/others/not_found.png");

  const tags = [
    "中文",
    "台語",
    "健體領域",
    "家庭關係",
    "社會領域",
    "藝術領域",
    "性平教育",
    "情感教育",
    "文化歷史",
    "職人",
    "本土文化",
    "環境教育",
    "生命教育",
    "兒童心理",
    "勞工議題",
    "勵志成長",
    "名人傳記",
    "新住民",
    "真人真事",
    "移民",
  ];
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h1'>查無搜尋結果</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction='column'
            justify='flex-start'
            alignItems='center'
          >
            <Grid item xs={12}>
              <img className={classes.mainImage} src={mainImage} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6'>
                我們找不到「<Box className={classes.keyword}>釣魚台</Box>
                」相關的電影。
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.tagsContainer}>
            <Typography variant='h6'>
              建議您可透過以下標籤搜尋感興趣的電影：
            </Typography>
            <div className={classes.tags}>
              {tags.map(tag => (
                <VideoTagButton key={tag} name={tag} />
              ))}
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.recommend}>
            <Typography variant='h6'>或探索這些類型的電影：</Typography>
            <div className={classes.recommendList}>
              <Grid container spacing={0}>
                {mock.data.map(elm => (
                  <Grid item xs={3} key={elm.name}>
                    <a href={elm.link}>
                      <img
                        className={classNames(
                          classes.recommendImg,
                          classes.lightBackground,
                        )}
                        src={elm.src}
                      />
                    </a>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SearchNotFound.defaultProps = {};

SearchNotFound.propsTypes = {};

export default SearchNotFound;
