import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    color: "white",
    backgroundColor: "#363747",
    maxWidth: "100%",
    minWidth: "100%",
    // minHeight: '1px',
    position: "relative",
  },
  container: {
    textAlign: "center",
    paddingTop: "5%",
    paddingBottom: "5%"
  },
}));
