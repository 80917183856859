import React from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
    },
    label: {
      color: theme.palette.primary.light,
    },
    playButton: {
      overflow: "auto",
      width: 120,
      height: 40,
      fontSize: 18,
      borderRadius: "0",
    },
    playButtonLarge: {
      ...theme.typography.h3,
      overflow: "auto",
      width: 304,
      height: 72,
      borderRadius: "0",
      [theme.breakpoints.down("md")]: {
        width: 304 * 0.7,
        height: 72 * 0.7,
      },
    },
  }),
);

const PlayButton = props => {
  const classes = useStyles();
  const { size = "sm" } = props;
  const playButtonClass =
    size === "lg" ? classes.playButtonLarge : classes.playButton;
  return (
    <>
      <div>
        <Button
          variant='outlined'
          href='#/movie/detail'
          className={playButtonClass}
          classes={{ outlined: classes.root, label: classes.label }}
        >
          觀看影片
        </Button>
      </div>
    </>
  );
};

PlayButton.defaultProps = {};

PlayButton.propsTypes = {};

export default PlayButton;
