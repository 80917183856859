import React, { useRef } from "react";
import { usePlayerContext, VimeControl, VimeTooltip } from "@vime/react";
import {
  Forward10 as Forward10Icon,
  Replay10 as Replay10Icon,
} from "@material-ui/icons";

export default function VimeSeekButtonControl () {
  /**
   * We need a reference to a DOM element so the Vime hooks work as they rely on dispatching
   * custom DOM events.
   */
  const ref = useRef(null);

  // Little utility hook to get the current player, incase you need to call a method.
  // *** -> const player = usePlayer(ref);

  const [currentTime, setCurrentTime] = usePlayerContext(ref, "currentTime", 0);
  const [duration] = usePlayerContext(ref, "duration", -1);
  // TODO: sometimes i18n will be undefined
  const [i18n] = usePlayerContext(ref, "i18n", {});

  const durationUnit = 10;

  const onSeekBackward = () => {
    if (currentTime < durationUnit) return;
    // We are dispatching an update to the player to change the `currentTime` property.
    setCurrentTime(currentTime - durationUnit);
  };

  const onSeekForward = () => {
    if (currentTime > duration - durationUnit) return;
    setCurrentTime(currentTime + durationUnit);
  };

  return (
    <>
      <VimeControl
        ref={ref}
        keys={"ArrowLeft"}
        label={"seekBackward"}
        onClick={onSeekBackward}
      >
        <Replay10Icon />
        <VimeTooltip>{i18n ? i18n.seekBackward : ""}</VimeTooltip>
      </VimeControl>
      <VimeControl
        ref={ref}
        keys={"ArrowRight"}
        label={"seekForward"}
        onClick={onSeekForward}
      >
        <Forward10Icon />
        <VimeTooltip>{i18n ? i18n.seekForward : ""}</VimeTooltip>
      </VimeControl>
    </>
  );
}
