import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    maxWidth: "100vw",
    // maxWidth: 1440,
    // overflowX: "hidden",
  },
  menu: {
    paddingLeft: "5%",
    paddingRight: "5%",
    width: "100%",
  },
  content: {
    // width: `calc(100vw - 240px)`,
    width: "100%",
    maxWidth: 1440,
    minHeight: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
}));
