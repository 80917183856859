import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    height: 72,
  },
  label: {
    color: "black",
  },
  menu: {
    display: "flex",
    justifyContent: "space-between",
  },
  menuItem: {
    marginRight: theme.spacing(4),
    "&:last-child": {
      marginRight: 0,
    },
  },
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
    padding: theme.spacing(2),
    border: theme.spacing(2),
    borderRadius: "0 0",
    minWidth: 350,
  },
  menuGroupTitle: {
    color: "#252e78",
  },
  menuGroupItem: {
    paddingTop: 0,
  },
  caption: {
    ...theme.typography.smallCaption,
  },
  navLink: {
    color: theme.palette.common.grey[800],
    fontWeight: 400,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
}));
