import React, { useState } from "react";
import {
  Typography,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import { makeStyles, createStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  ThumbUpRounded as ThumbUpRoundedIcon, // TODO: change me
  BookmarkRounded as BookmarkRoundedIcon,
} from "@material-ui/icons";
import { ReactComponent as ThumbUpBorderRoundedIcon } from "../../images/icons/thumb-up.svg";
import { ReactComponent as BookmarkBorderRoundedIcon } from "../../images/icons/bookmark.svg";
import { ReactComponent as DocumentIcon } from "../../images/icons/document.svg";
import VideoTagButton from "../../components/VideoTagButton";

// context
import { useUserState, useUserDispatch } from "../../context/UserContext";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      margin: "6.5%",
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    title: {
      color: "black",
    },
    interactionButton: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    },
    interactionButtonText: {
      ...theme.typography.subtitle2,
      fontWeight: 700,
      color: "black",
    },
    tag: {
      marginRight: theme.spacing(1),
      paddingLeft: 14,
      paddingRight: 14,
      borderRadius: theme.spacing(2),
      backgroundColor: "#e0e0e0",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    content: {
      marginTop: theme.spacing(2.5 - 1),
    },
    info: {
      marginTop: theme.spacing(1),
    },
    subtitle: {
      color: theme.palette.common.grey[800],
      fontWeight: 700,
    },
    colorBlack: {
      color: "black",
    },
    body: {
      color: theme.palette.common.grey[800],
    },
    dropDownList: {
      boxShadow: "0 1px 5px 1px rgba(116, 116, 116, 0.25)",
    },
    downloadListItem: {
      ...theme.typography.body2,
    },
    dialogPaper: {
      padding: theme.spacing(1),
    },
    dialogTitle: {
      ...theme.typography.subtitle1,
      fontWeight: 500,
    },
    dialogDescription: {
      ...theme.typography.smallCaption,
    },
    dialogButton: {
      ...theme.typography.caption,
      fontWeight: 700,
    },
  }),
);

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function MovieDescription () {
  const classes = useStyles();
  const theme = useTheme();

  const userDispatch = useUserDispatch();
  const { isAuthenticated } = useUserState();

  const [likeState, setLikeState] = useState(false);
  const [bookmarkState, setBookmarkState] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const requireLoginWrapper = cb => {
    if (isAuthenticated) {
      return cb;
    } else {
      return () => {
        handleDialogOpen();
      };
    }
  };

  const handleLikeClick = requireLoginWrapper(() => setLikeState(!likeState));
  const handleBookmarkClick = requireLoginWrapper(() =>
    setBookmarkState(!bookmarkState),
  );

  const tags = ["中文", "新上架", "家庭關係", "文學改編", "熱門電影"];

  const interactionButtonJustify = useMediaQuery(theme.breakpoints.down("md"))
    ? "flex-start"
    : "flex-end";

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant='h3' className={classes.title}>
                <Box fontWeight='fontWeightBold'>解憂雜貨店</Box>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                direction='row'
                justify={interactionButtonJustify}
                alignItems='center'
                spacing={1}
              >
                {/* <Grid item>
                  <Button
                    className={classes.interactionButton}
                    startIcon={
                      likeState ? (
                        <ThumbUpRoundedIcon />
                      ) : (
                        <ThumbUpBorderRoundedIcon />
                      )
                    }
                    onClick={handleLikeClick}
                  >
                    <Box className={classes.interactionButtonText}>
                      推薦影片
                    </Box>
                  </Button>
                </Grid> */}
                <Grid item>
                  <Button
                    className={classes.interactionButton}
                    startIcon={
                      bookmarkState ? (
                        <BookmarkRoundedIcon />
                      ) : (
                        <BookmarkBorderRoundedIcon />
                      )
                    }
                    onClick={handleBookmarkClick}
                  >
                    <Box className={classes.interactionButtonText}>
                      收藏影片
                    </Box>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.interactionButton}
                    startIcon={<DocumentIcon />}
                    aria-controls='simple-menu'
                    aria-haspopup='true'
                    onClick={handleClick}
                  >
                    <Box className={classes.interactionButtonText}>
                      下載附件
                    </Box>
                  </Button>
                  <Menu
                    id='simple-menu'
                    classes={{ paper: classes.dropDownList }}
                    anchorEl={anchorEl}
                    keepMounted
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    autoFocus={false}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={handleClose}
                      className={classes.downloadListItem}
                    >
                      康軒學習單1
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      className={classes.downloadListItem}
                    >
                      康軒學習單2
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      className={classes.downloadListItem}
                    >
                      康軒學習單3
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item>
              <Typography variant='subtitle1' className={classes.subtitle}>
                日語 | 中文字幕 | 普遍級
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='subtitle1' className={classes.subtitle}>
            The Miracles Of The Namiya General Store
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            {tags.map(tag => (
              <Grid item key={tag}>
                <VideoTagButton name={tag}></VideoTagButton>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <div className={classes.content}>
          <Grid item xs={12}>
            <Typography variant='subtitle2' className={classes.colorBlack}>
              劇情介紹
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.info}>
            <Typography variant='body2' className={classes.body}>
              2012年某天，夜不歸戶的少年敦也（山田涼介飾）和2個朋友逃竄在街道上，為了等天亮，他們進一間已經廢棄的雜貨店。一封突然投進的信，讓他們驚荒失措，而3人發現這竟是來自32年前寫給雜貨店老闆浪矢雄治（西田敏行
              飾）諮詢煩惱的信…。在這家「浪矢雜貨店」，只要在晚上把寫了煩惱的信投入鐵捲門中，隔天就可以在店後的牛奶箱得到回信。此時他們還不知道，這是間解救過許多人心靈的雜貨店。而且這一晚，將會是改變他們人生的「奇蹟」之夜…。
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.info}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant='subtitle2' className={classes.colorBlack}>
                  導演
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' className={classes.body}>
                  廣木隆一
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.info}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant='subtitle2' className={classes.colorBlack}>
                  演員
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' className={classes.body}>
                  山田涼介、成海璃子、林遣都、尾野真千子、西田敏行
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <div>
        <Dialog
          classes={{ paper: classes.dialogPaper }}
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleDialogClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle
            id='alert-dialog-slide-title'
            className={classes.dialogTitle}
          >
            {"使用進階功能？"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id='alert-dialog-slide-description'
              className={classes.dialogDescription}
            >
              您需要加入飛行會員以使用進階功能。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              color='primary'
              className={classes.dialogButton}
            >
              返回
            </Button>
            <Button
              onClick={() => userDispatch({ type: "LOGIN_SUCCESS" })}
              color='primary'
              className={classes.dialogButton}
              href='#login'
            >
              登入/註冊
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
