import defaultTheme from "./default";

import { createMuiTheme } from "@material-ui/core";

const overrides = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      tablet: 640,
      laptop: 1024,
      desktop: 1280,
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: [
      '"Noto Sans TC"',
      "sans-serif",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: 28,
      fontWeight: 700,
      lineHeight: 1.14,
      letterSpacing: 3,
      color: "#212121",
    },
    h2: {
      fontSize: "3rem",
      fontWeight: 300,
      lineHeight: 1.2,
      letterSpacing: -0.5,
    },
    h3: {
      fontSize: 32,
      fontWeight: 500,
      lineHeight: 1.75,
    },
    h4: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 1,
    },
    h5: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h6: {
      fontSize: 20,
      lineHeight: 1.2,
    },
    tags: {
      fontSize: 18,
      lineHeight: 1.11,
      letterSpacing: 1,
    },
    button: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: 0.15,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.75,
      letterSpacing: 0.15,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.71,
      letterSpacing: 0.1,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.71,
      letterSpacing: 0.15,
    },
    caption: {
      fontSize: 12,
      fontWeight: 700,
    },
    smallCaption: {
      fontSize: 12,
      lineHeight: 1.67,
    },
  },
};

export default {
  default: createMuiTheme({ ...defaultTheme, ...overrides }),
};
