import React, { useRef, useState } from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { usePlayer, usePlayerContext } from "@vime/react";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import classNames from "classnames";

import LoginAlertDialog from "../../components/LoginAlertDialog";

// styles
import useStyles from "./styles";

// context
import { useUserState } from "../../context/UserContext";
import {
  handleMaskTopButton,
  handleMaskBottomButton,
  useVideoDispatch,
  useVideoState,
} from "../../context/VideoContext";

export default function PauseMask () {
  /**
   * We need a reference to a DOM element so the Vime hooks work as they rely on dispatching
   * custom DOM events.
   */
  // global
  const ref = useRef(null);
  const player = usePlayer(ref);
  const videoDispatch = useVideoDispatch();
  const [currentTime] = usePlayerContext(ref, "currentTime", 0);
  const [paused, setPaused] = usePlayerContext(ref, "paused", true);
  const { isAuthenticated } = useUserState();
  const { videoType } = useVideoState();

  // local
  const isLogin = isAuthenticated;
  const isTrailer = videoType === "trailer";
  const isInit = currentTime === 0;

  const [open, setOpen] = useState(false);

  // SPEC 少一個情境: 已登入，播完預告片直接換成播放正片
  const getTopWord = (isInit, isTrailer) => {
    if (isInit) {
      if (isTrailer) {
        return "播放預告片";
      }
      return "播放正片";
    }
    return "繼續播放";
  };
  const getBottomWord = (isLogin, isTrailer) => {
    if (!isTrailer) {
      return "播放預告片";
    }
    return "播放正片";
  };
  const topWord = getTopWord(isInit, isTrailer);
  const bottomWord = getBottomWord(isLogin, isTrailer);

  // TODO: geo location error

  const classes = useStyles();

  return (
    <div
      ref={ref}
      className={classNames(classes.root, { [classes.hide]: paused === false })}
    >
      <Grid
        container
        direction='column'
        justify='center'
        alignItems='center'
        spacing={2}
      >
        <Grid item>
          <Button
            className={classes.maskPlayButton}
            variant='contained'
            color='primary'
            startIcon={<PlayArrowIcon style={{ fontSize: 24 }} />}
            onClick={() => {
              handleMaskTopButton(player);
            }}
          >
            {topWord}
          </Button>
        </Grid>
        <Grid item>
          <Button
            classes={{ label: classes.maskPlayText }}
            variant='text'
            onClick={() => {
              handleMaskBottomButton(videoDispatch, isLogin, isTrailer, player);
              setOpen(true);
            }}
          >
            {bottomWord}
          </Button>
        </Grid>
      </Grid>
      <LoginAlertDialog open={open} setOpen={setOpen} />
    </div>
  );
}
