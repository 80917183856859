import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    backgroundImage:
      "linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 50%)",
  },
  albumItem: {
    label: {
      color: "black",
    },
    margin: "5px",
    "&:first-child": {
      marginLeft: theme.spacing(0),
    },
    overflow: "hidden",
  },
  extraMarginBottom: {
    marginBottom: theme.spacing(2),
  },
  poster: {
    position: "relative",
    "&:hover $play": {
      opacity: 1,
    },
  },
  enlargePoster: {
    "&:hover $imgFluid": {
      transform: "scale(1.4)",
    },
  },
  imgFluid: {
    maxWidth: "100%",
    height: "auto",
    transition: "transform .2s",
  },
  play: {
    display: "flex",
    position: "absolute",
    width: "100%",
    height: "100%",
    overflow: "auto",
    margin: "auto",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
    transitionDuration: "0.5s",
    opacity: 0,
    overflow: "hidden",
  },
  playButton: {
    overflow: "hidden",
    width: 120,
    height: 40,
    marginTop: theme.spacing(2),
  },
  playMask: {
    backgroundColor: "rgba(0, 0, 0, 0.65)",
  },
  playMaskDetail: {
    padding: theme.spacing(2.5),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    color: "white",
    "&:hover $imgFluid": {
      transform: "scale(1.4)",
    },
  },
  maskMeta: {
    ...theme.typography.smallCaption,
    color: theme.palette.common.grey[300],
    marginTop: theme.spacing(0.5),
  },
  maskIntro: {
    ...theme.typography.smallCaption,
    marginTop: theme.spacing(0.5),
  },
  info: {
    marginTop: 4,
  },
  title: {
    color: theme.palette.common.grey[900],
    fontWeight: 700,
  },
  caption: {
    ...theme.typography.smallCaption,
    color: theme.palette.common.grey[600],
  },
  relative: {
    position: "relative",
  },
  blockWithText: {
    maxWidth: "10rem",
    overflow: "hidden",
    position: "relative",
    lineHeight: "1.67em",
    maxHeight: `${1.67*5}em`,
    textAlign: "justify",
  },
}));
