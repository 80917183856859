export default {
  banners: [
    {
      id: 0,
      src: "https://static-img.linetv.tw/1460a4031f02f11b31e80525bf2c7d230348a995d4637a37d91a7822ebe24b2266bf3b8eb7d3eb0af088eee2f0a0f5dec991f748a963caf4efd09276154f046d161097192862183694d18bf2efebc94941a817692c53931b949c5e607ba581bafbca6124fb8857c2aaa9a021736b22fd76b789571ddc2fcfd1de2822c9cdbac5892a174dcbf9e518cf037f8e94b50efff03d99d1348369217c2fb712e740311e494099309a8dee15deb399eee23c97e19e1f4d75fe0c886fc9ff0a69065e62c78c39063a4cfcd33fc994fbdb2e172427"  
    },
    {
      id: 1,
      src: "https://static-img.linetv.tw/1460a4031f02f11b31e80525bf2c7d230348a995d4637a37d91a7822ebe24b2266bf3b8eb7d3eb0af088eee2f0a0f5def6c8f60f4784f4123ae6305917cc9cdc76aa8623ae1a94de5db15cb4c69a9f5e7ecbc1cfbd9e11292085c61f0fb991c6215b6cee2f67b40bd3c2b200b861cb85cc5077cec30931bb914040f570f729119838fce135aa96862219dd57114dc68da4abd23bbb00af9d15366d99e01c82506dd0162488f09bcaa74c7f7f8e52684067aca2c98865bb0fb53d290a36e3a5beca21541c3a4c88531300452290fcf34e"
    },
    {
      id: 2,
      src: "https://static-img.linetv.tw/1460a4031f02f11b31e80525bf2c7d230348a995d4637a37d91a7822ebe24b2266bf3b8eb7d3eb0af088eee2f0a0f5de174cecdf91cf435ace12c425f3285780114006d206f5ce13e58fc4b48b4c9f932fb9828d9eaa1f07dceabade732cad97246c36fa51bd0c31354a7ce5f222815555b52d235c4b15592e826db286baeb4d9f18c018297d80d47e5e1c259f4da63227ff77ebb752e0b4236d2560155afe0128b4edb70d16c60a8b0cbeab5f7ff07b74489f1642754f7d728f4b9f55dcf240953d29eba2d9535ddaf30ed892acc587"
    },
    {
      id: 3,
      src: "https://static-img.linetv.tw/1460a4031f02f11b31e80525bf2c7d230348a995d4637a37d91a7822ebe24b2266bf3b8eb7d3eb0af088eee2f0a0f5dec991f748a963caf4efd09276154f046d022bbc25d9dc526c3a6fb7a226c5baa9ae0a4a186c4b3ee467c170ef75458f52c7480f84d60aa3f9815b06b2d17d76596c910221259c3c13cb3226c68c770ef17fa13d4371c93987065ac0c9dcdbff7b488b46ab444a0a6f114bbb0137aeedee0402df542bf583517c6322831198b11a9fadb400b9c40dfa56508e8134be5367e428a7d257d7f7232c69f7d5ab92c8d2"
    }
  ]
};
