import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    display: "flex",
    position: "absolute",
    width: "100%",
    height: 80,
    backgroundImage: "linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0))",
  },
  header: {
    ...theme.typography.h6,
    position: "relative",
    color: "#ffffff",
    paddingTop: 27,
    paddingBottom: 27,
    paddingLeft: 24,
    paddingRight: 24,
  },
}));
