import React from "react";
import PropTypes from "prop-types";

import { Typography, Box, Grid } from "@material-ui/core";
import classNames from "classnames";

import useStyles from "./styles";

import PlayButton from "../PlayButton";

const MovieAlbumItem = ({
  item,
  showMeta,
  showTitle,
  hoverMaskMode,
  hoverEnlargePoster,
  extraMarginBottom,
}) => {
  const classes = useStyles();

  const HoverMask = ({ display }) => {
    if (hoverMaskMode === "simple") {
      return (
        <Box
          className={classNames(classes.play, classes.playMask)}
          display={display}
        >
          <PlayButton />
        </Box>
      );
    }

    return (
      <div
        className={classNames(
          classes.play,
          classes.playMask,
          classes.playMaskDetail,
        )}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.maskTitle} variant='h5'>
              {item.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.maskMeta}>{item.others}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              className={classNames(classes.maskIntro, classes.blockWithText)}
            >
              毒梟是一部美國犯罪網路電視劇，由克里斯·布蘭卡托、Carlo
              Bernard和道格·米羅創作和監製。描述二十世紀八零年代哥倫比亞大毒梟巴勃羅·埃斯科瓦爾，是怎麼靠著自身的聰明及手段......
            </Box>
          </Grid>

          <Grid container item justify='center'>
            <Grid item>
              <Box className={classes.playButton}>
                <PlayButton />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <div
      className={classNames(classes.albumItem, {
        [classes.extraMarginBottom]: extraMarginBottom,
      })}
    >
      <div
        className={classNames(classes.poster, {
          [classes.enlargePoster]: hoverEnlargePoster,
        })}
      >
        <div
          className={classNames({ [classes.relative]: !hoverEnlargePoster })}
        >
          <img
            src={item.src}
            alt={item.name}
            className={classes.imgFluid}
          ></img>
          <HoverMask />
        </div>
        <div className={classes.info}>
          <Typography variant='h5' className={classes.title}>
            <Box display={showTitle ? "block" : "none"}>{item.name}</Box>
          </Typography>
          <div className={classes.caption}>
            <Box display={showMeta ? "block" : "none"}>{item.others}</Box>
          </div>
        </div>
      </div>
    </div>
  );
};

MovieAlbumItem.defaultProps = {
  showMeta: true,
  showTitle: true,
};

MovieAlbumItem.propsTypes = {};

export default MovieAlbumItem;
