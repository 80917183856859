import React from "react";

// styles
import useStyles from "./styles";

export default function PauseMask () {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>解憂雜貨店</div>
    </div>
  );
}
